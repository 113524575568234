<template>
	<div>
		<a-modal title="指定客服" :width="700" :visible="visible"  @cancel="handleCancel" :footer="null">
			<div>
				<div class="wxb-table-gray">
					<a-table rowKey="customer_service_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas" :loading="loading">
						<div slot="qrcode" slot-scope="qrcode,record">
							<img v-if="qrcode!=null" :src="qrcode" style="width: 40px;height: 40px;"/>
							<img v-else src="../../../../../assets/image/kefu.png" style="width: 40px;height: 40px;"/>
						</div>
						
						<template slot="action" slot-scope="text,record">
							<a-button type="primary" @click="chooseIt(record)">选择</a-button>
						</template>
					</a-table>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../common/mixin/list.js';
	export default {
		mixins:[listMixin],
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			customer_service_id:{
				type:Number,
				default:0,
			},
			shop_id:{
				type:Number,
				default:0,
			},
		},
		data() {
			return {
				loading: false,
				columns: [
					{title: 'ID编号',dataIndex: 'customer_service_id',align: 'center',ellipsis: true},
					{title: '客服名称',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: '客服二维码',dataIndex: 'qrcode',align: 'center',scopedSlots: {customRender: 'qrcode'}},
					{title: '客服手机号',dataIndex: 'mobile',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
					
				],
				datas: [],
			}
		},
		created() {
			
		},
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/getKefuList',{
					customer_service_id:this.customer_service_id,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
				}).finally(()=>{
					this.loading=false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			chooseIt(record){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/appointKefu',{
					shop_id:this.shop_id,
					customer_service_id:record.customer_service_id,
				}).then(res=>{
					this.$message.success('指定成功',1,()=>{
						this.loading=false;
						this.$emit('ok');
					})
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
			
		}
	}
</script>

<style>
</style>
